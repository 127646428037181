import React from 'react';

const PhoneSvgIcon = ({width = 40, height = 40, color = '#FFF'}) => (
  <svg xmlns="http://www.w3.org/2000/svg"
       width={width}
       height={height}
       fill={color}
       viewBox="0 0 512 512">
    <g>
      <g>
        <path d="M498.846,397.887l-84.833-84.832c-17.581-17.582-46.039-17.585-63.623,0l-21.209,21.209
			c-17.544,17.542-46.084,17.54-63.694-0.07l-88.822-87.646c-17.542-17.542-17.542-46.082,0-63.624l21.208-21.208
			c17.542-17.541,17.542-46.082,0-63.624L113.042,13.26C95.5-4.282,66.957-4.28,49.418,13.26L28.212,34.468
			C0.684,61.995-7.412,122.687,7.083,192.857c9.238,44.721,35.815,130.824,110.02,205.029
			c74.055,74.056,159.46,99.471,203.777,108.029c48.713,9.407,121.92,11.639,156.757-23.196l21.21-21.209
			C516.385,443.969,516.385,415.428,498.846,397.887z M70.628,34.468c5.848-5.847,15.36-5.848,21.209,0l84.832,84.832
			c5.848,5.848,5.848,15.361,0,21.209l-10.551,10.551L60.076,45.02L70.628,34.468z M326.568,476.467
			c-40.948-7.907-119.855-31.386-188.257-99.788C69.631,307.997,45.016,228.227,36.456,186.79
			c-11.339-54.888-6.618-96.147,4.392-118.583l105.117,105.118c-19.291,29.105-16.121,68.817,9.564,94.501l88.822,87.646
			c25.611,25.611,65.3,28.8,94.43,9.494l105.202,105.203C411.925,486.426,362.07,483.322,326.568,476.467z M477.638,440.302
			l-10.552,10.552L361.046,344.815l10.551-10.551c5.86-5.861,15.346-5.861,21.208,0l84.832,84.832
			C483.484,424.942,483.484,434.456,477.638,440.302z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M287.033,0.115c-8.282,0-14.996,6.714-14.996,14.996s6.714,14.996,14.996,14.996
			c107.496,0,194.951,87.455,194.951,194.951c0,8.282,6.714,14.996,14.996,14.996c8.282,0,14.996-6.714,14.996-14.996
			C511.976,101.024,411.067,0.115,287.033,0.115z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M287.033,60.1c-8.282,0-14.996,6.714-14.996,14.996s6.714,14.996,14.996,14.996c74.42,0,134.966,60.546,134.966,134.966
			c0,8.282,6.714,14.996,14.996,14.996s14.996-6.714,14.996-14.996C451.991,134.1,377.991,60.1,287.033,60.1z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M287.033,120.085c-8.282,0-14.996,6.714-14.996,14.996c0,8.282,6.714,14.996,14.996,14.996
			c41.345,0,74.981,33.636,74.981,74.981c0,8.282,6.714,14.996,14.996,14.996s14.996-6.714,14.996-14.996
			C392.007,167.176,344.915,120.085,287.033,120.085z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M287.033,180.07c-8.282,0-14.996,6.714-14.996,14.996s6.714,14.996,14.996,14.996c8.269,0,14.996,6.727,14.996,14.996
			c0,8.282,6.714,14.996,14.996,14.996c8.282,0,14.996-6.714,14.996-14.996C332.022,200.251,311.84,180.07,287.033,180.07z"/>
      </g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
  </svg>
)

export default PhoneSvgIcon
